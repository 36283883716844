@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.whiteBackgroundWrapper{
  & form{
    & input{
      &::placeholder{
        color: var(--colorGrey700);
      }
    }
  }
  & >div{
    & >div{
      & a{
        &:first-child{
          color: var(--marketplaceColor);
        }
        color: var(--colorGrey700);
        text-decoration: none;

        &:hover {
          color: var(--marketplaceColorDark);
          border-bottom: 4px solid var(--marketplaceColor);
          border-radius: 0;
          text-decoration: none;
        }
      }

      &:nth-child(2){
        & button{
          color: var(--colorGrey700);
          &:hover {
            color: var(--marketplaceColorDark);
            border-bottom: 4px solid var(--marketplaceColor);
            border-radius: 0;
            text-decoration: none;
          }
        }
      }

    } 
  }
}

.whiteBackgroundTopbarLink{
  color: #4A4A4F !important;
  
  &:hover {
    color: var(--marketplaceColorDark) !important;
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.tempLogoContainer{
  & a{
    &:nth-child(1){
      display: block;
    }
    &:nth-child(2){
      display: none;
    }
  }
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 15px 24px;
  position: relative;

  @media (--viewportMedium) {
    padding: 21px 24px;
  }
}
.searchMenu {
  padding: 12px 24px;

  @media (--viewportMedium) {
    padding: 18px 24px;
  }
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  @media (--viewportMedium) {
    top: 23px;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--colorGrey700);
}
.rootSearchIcon {
  stroke: var(--colorGrey700);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: max(100%, 100vh);
    height: auto;
    padding: 24px;
    background-color: var(--colorWhite);
    border-bottom: none;
  }
}
.modalContainerSearchForm {
  composes: modalContainer;
  padding: 0;
  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}
